import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'actas-testing-parameters',
  templateUrl: './actas-testing-parameters.component.html',
  styleUrls: ['./actas-testing-parameters.component.scss']
})
export class ActasTestingParametersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
