import { environment } from '../../environments/environment';

export class GlobalURL {

  // base paths
  public static BASE_PATH = environment.BASE_URL;

  public static SIGNALRMessage = GlobalURL.BASE_PATH + 'signalr/get';
  // Order fullfilment URL's
  // get sync information
  public static SYNC_STATUS = GlobalURL.BASE_PATH + 'order-management/orders/syncstatus';
  // get dropdown values
  public static STATUS_VALUES = GlobalURL.BASE_PATH + 'order-management/orders/status';
  // get order list
  public static ORDER_LIST = GlobalURL.BASE_PATH + 'order-management/orders/suppliers/';
  // get article number list
  public static ARTICLE_NO_LIST = GlobalURL.BASE_PATH + 'order-management/';
  // // get counts list
  public static COUNT_DATA = GlobalURL.BASE_PATH + 'order-management/supplier/cervalidation/';
  // get split order details
  public static SPLIT_ORDER_LIST = GlobalURL.BASE_PATH + 'order-management/orders/configureordersplitdetails/supplier/';
  // create split order
  public static CREATE_SPLIT_ORDER = GlobalURL.BASE_PATH + 'order-management/orders/configureordersplit/supplier/';
  // update split order
  public static UPDATE_SPLIT_ORDER = GlobalURL.BASE_PATH + 'order-management/orders/configureorderupdatesplit/supplier/';
  // merge split order
  public static MERGE_SPLIT_ORDER = GlobalURL.BASE_PATH + 'order-management/orders/configureordermerge/supplier/';
  // check split order lastupdated
  public static CHECK_SPLIT_ORDER_STATUS = GlobalURL.BASE_PATH + 'order-management/orders/deletedorders/';
  // check group order
  public static GET_ORDERLINE_DETAIL_FOR_GROUPING = GlobalURL.BASE_PATH + 'order-management/orders/GetOrderLineDetailForGrouping/';
  // get grouped order list
  public static GET_GROUPED_ORDERLINE_DETAIL = GlobalURL.BASE_PATH + 'order-management/orders/GetGroupedOrderLineDetail/';
  // set group order from order line list
  public static GROUP_AND_UNGROUP_ORDERLINE = GlobalURL.BASE_PATH + 'order-management/orders/GroupAndUngroupOrderLine/';

  public static UPLOAD_TEMPLATE = GlobalURL.BASE_PATH + 'order-management/orders/saveexceldata';

  public static GET_TRACEABILITY = GlobalURL.BASE_PATH + 'order-management/orders/gettraceability';

  public static SAVE_COMPONENT_DATA = GlobalURL.BASE_PATH + 'order-management/orders/savecomponentdeta';

  public static DOWNLOAD_TEMPLATE = GlobalURL.BASE_PATH + 'quality-controller-management/quality/DownloadIQC';

  public static CERT_REVIEW_DATA = GlobalURL.BASE_PATH + 'quality-controller-management/Supplier/Certficates';

  public static PENDING_CERTS__DATA = GlobalURL.BASE_PATH + 'quality-controller-management/Supplier/PendingCertficatesOpt';

  public static PENDING_CERTS_FILTERS = GlobalURL.BASE_PATH + 'quality-controller-management/Supplier/PendingCertficatesFilters';

  // country api
  public static GET_COUNTRY_LIST = GlobalURL.BASE_PATH + 'common-management/searchcountry';

  // Login API's
  // get info of login user
  public static LOGIN = GlobalURL.BASE_PATH + 'user-management/users/useremail';
  // update last login time
  public static UpdateLoginDate = GlobalURL.BASE_PATH + 'user-management/users/configureuserlastlogin/';

  // Admin User APi's
  // Get user list
  public static getUserList = GlobalURL.BASE_PATH + 'user-management/users/userlist/';
  // Get user by ID
  public static userDetailInfo = GlobalURL.BASE_PATH + 'user-management/users/userid/';
  // search user in metadata by email
  public static searchUserMetadata = GlobalURL.BASE_PATH + 'user-management/users/usermaster/';
  // get all user group
  public static userGroupList = GlobalURL.BASE_PATH + 'user-management/users/usergroups';
  // get all user roles
  public static userRoleListAll = GlobalURL.BASE_PATH + 'user-management/users/usergroup/roles/userrole';
  // get user role by grp name
  public static userRoleListByGrpName = GlobalURL.BASE_PATH + 'user-management/users/usergroupname/';
  // get all user type
  public static userTypeListAll = GlobalURL.BASE_PATH + 'user-management/users/usergrouptypes';
  // get user type by group and role
  public static userTypeList = GlobalURL.BASE_PATH + 'user-management/users/usergroups/';
  // get flag for optional field
  public static getUserAdminFlag = GlobalURL.BASE_PATH + 'user-management/users/usergroups/';
  // toggle user status
  public static userChangeStatus = GlobalURL.BASE_PATH + 'user-management/users/configureuserdeactivate/';
  // save user
  public static saveUser = GlobalURL.BASE_PATH + 'user-management/users/configureusersave';
  // edit user
  public static editUser = GlobalURL.BASE_PATH + 'user-management/users/configureuseredit/';

  // Admin Supplier API's
  // get supplier list
  public static getSupplierList = GlobalURL.BASE_PATH + 'supplier-management/suppliers/supplierlist/';
  // get supplier by id
  public static getSupplierDetail = GlobalURL.BASE_PATH + 'supplier-management/suppliers/supplierdetail/';
  // search supplier in metadata
  public static searchSupplierMetadata = GlobalURL.BASE_PATH + 'supplier-management/suppliers/suppliermaster/';
  // save supplier
  public static saveSupplier = GlobalURL.BASE_PATH + 'supplier-management/suppliers/configuresupplier';

  // Admin Customer API's
  // get customer list
  public static getCustomerList = GlobalURL.BASE_PATH + 'customer-management/customers/customerlist/';
  // get customer detail by id
  public static getCustomerDetail = GlobalURL.BASE_PATH + 'customer-management/customers/customerdetail/';
  // search customer in master data
  public static onboardCustomerInfo = GlobalURL.BASE_PATH + 'customer-management/customers/customermaster/';
  // save customer
  public static saveCustomer = GlobalURL.BASE_PATH + 'customer-management/customers/configurecustomer';

  // Admin Product API's
  // get product list
  public static GET_PRODUCT_LIST = GlobalURL.BASE_PATH + 'product-management/products/';

  public static GENERATE_TEST_REPORT = GlobalURL.BASE_PATH + 'production-management/GenerateTestReport/article/';

  // add product
  public static ADD_PRODUCT = GlobalURL.BASE_PATH + 'product-management/products/configureadd';
  // edit product
  public static EDIT_PRODUCT = GlobalURL.BASE_PATH + 'product-management/products/configureupdate';

  public static GET_PRODUCT_TREE = GlobalURL.BASE_PATH + 'product-management/productClassification';

  public static VALIDATE_PRODUCT = GlobalURL.BASE_PATH + 'product-management/products/validatearticlenumber/';

  // '../assets/adminProduct.json';

  // public static GET_PRODUCT_TREE = '../assets/adminProduct.json';

  // Admin Component API's
  // get component list
  public static GET_COMPONENT_LIST = GlobalURL.BASE_PATH + 'component-management/components/';

  // Get component by Article number
  public static GET_COMPONENT_DETAILS = GlobalURL.BASE_PATH + 'component-management/components/articles/';


  // Component/GetTraceabilityType
  public static GET_TRACEABILITY_TYPES = GlobalURL.BASE_PATH + 'component-management/components/traceability';

  // Update Component
  public static UPDATE_COMPONENT = GlobalURL.BASE_PATH + 'component-management/components/configureedit';

  // Add Component
  public static ADD_COMPONENT = GlobalURL.BASE_PATH + 'component-management/components/configuresave';

  // generate barcode
  public static GENERATE_BARCODE = GlobalURL.BASE_PATH + 'barcode-management/barcode/generatebarcodedetails';

  // detele barcode
  public static DELETE_BARCODE = GlobalURL.BASE_PATH + 'barcode-management/barcode/deletebarcodedetails';


  // generate barcode for Zebra
  public static GENERATE_BARCODE_ZEBRA = GlobalURL.BASE_PATH + 'barcode-management/barcode/generateZebrabarcodedetails';

  public static GENERATE_BARCODE_IMAGE_ZIP = GlobalURL.BASE_PATH + 'barcode-management/barcode/GenerateBarcodeImagesZip';
  // validate table
  public static VALIDATE_TABLE = GlobalURL.BASE_PATH + 'order-management/orders/configureordervalidate/order/';
  // get order details
  public static ORDER_DETAIL = GlobalURL.BASE_PATH + 'order-management/orders/order/';
  // get document list
  public static DOCUMENT_LIST = GlobalURL.BASE_PATH + 'common-management/documents/order/';
  // submit document list
  public static SUBMIT_DOCUMENT_LIST = GlobalURL.BASE_PATH + 'common-management/documents/order/orderlines/';
  // get validate order list
  public static VALIDATE_ORDER_LIST = GlobalURL.BASE_PATH + 'order-management/orders/orderdetail/traceabilitylist/order/';
  // get reference drawing document
  public static GET_REFERENCE_DRAWING = GlobalURL.BASE_PATH + 'component-management/components/saveComponentDocument/componentId/';

  // iQC url's
  // search order line
  public static SEARCH_ORDER_DETAILS = GlobalURL.BASE_PATH + 'order-management/orders/order/';
  // search order line by CCRP number
  public static GET_SERIALNUMBERS_BASED_ON_CCRPNO = GlobalURL.BASE_PATH + 'order-management/getserialnumbersbasedonccrpno';
  // add note
  public static ADD_NOTES = GlobalURL.BASE_PATH + 'common-management/notes/documents/articles/';
  // list documents
  public static DOCUMENT_LIST_DETAILS = GlobalURL.BASE_PATH + 'common-management/documents/categories/order/';
  // milestone list api
  public static HISTORY_DETAILS = GlobalURL.BASE_PATH + 'quality-controller-management/quality/milestonehistory/articles/';
  // milestone list api for customer
  public static CUSTOMER_HISTORY_DETAILS = GlobalURL.BASE_PATH + 'quality-controller-management/quality/' +
    'customerproductmilestonehistory/articles/';
  // document list api for customer
  public static CUSTOMER_DOCUMENT = GlobalURL.BASE_PATH + 'common-management/customerdocuments/categories/order/';
  // barcode image api
  public static GET_BARCODE_IMAGE = GlobalURL.BASE_PATH + 'barcode-management/barcode/printbarcodeimagedetails';
  // Resubmit order
  public static RESUBMIT_ORDER = GlobalURL.BASE_PATH + 'quality-controller-management/ResubmitIQC/orderLineId/';

  public static IQC_SUMMERY = GlobalURL.BASE_PATH + 'quality-controller-management/quality/DownloadIQCInspectionData/';

  // production url

  // get article number list
  public static GET_ARTICLENUMBER_LIST = GlobalURL.BASE_PATH + 'production-management/factory/';
  // get serial number
  public static GET_SERIALNUMBER = GlobalURL.BASE_PATH + 'production-management/orders/';
  // print tracking card
  public static PRINT_TRACKING_CARD = GlobalURL.BASE_PATH + 'barcode-management/barcode/printtrackingcarddetails/';
  // reprint tracing barcode
  public static REPRINT_TRACKING_BARCODE = GlobalURL.BASE_PATH + 'production-management/barcode/GetReprinttrackingcarddetails';

  public static PRINT_CONFIGURATION = GlobalURL.BASE_PATH + 'product-management/printproductionconfiguration/products/';
  // confirm product
  public static CONFIRM_PRODUCT = GlobalURL.BASE_PATH + 'production-management/articles/';
  // process details
  public static GET_PROCESS_DETAILS = GlobalURL.BASE_PATH + 'production-management/orders/';
  // production order from SN details
  public static GET_PO_FROM_SN = GlobalURL.BASE_PATH + 'production-management/GetProductionIOrderFromSN/';
  // process details new
  public static GET_PROCESS_DETAILS_NEW = GlobalURL.BASE_PATH + 'production-management/articles/';
  // get production sales orders
  public static GET_SALES_ORDERS = GlobalURL.BASE_PATH + 'production-management/factory/';
  // save production step
  public static SAVE_PRODUCTION = GlobalURL.BASE_PATH + 'production-management/factory/';
  // save production time capture
  public static SAVE_PRODUCTION_TIME_CAPTURED_DETAILS = GlobalURL.BASE_PATH + 'production-management/saveproductiontimecapturedetails';
  // save serial number
  public static SAVE_SERIAL_NUMBER = GlobalURL.BASE_PATH + 'production-management/draftorderlisting/';
  // submit serial number
  public static SUBMIT_SERIAL_NUMBER = GlobalURL.BASE_PATH + 'production-management/submitorderlisting/';
  // print order listing
  public static PRINT_ORDER_LISTING = GlobalURL.BASE_PATH + 'barcode-management/barcode/order/';
  // show barcode
  public static SHOW_BARCODE_REGISTER_PRODUCT = GlobalURL.BASE_PATH + 'production-management/barcodeimage/factory/';

  // get factory List
  public static GET_FACTORY_LIST = GlobalURL.BASE_PATH + 'factory-management/factories/';
  public static GET_FACTORY_BY_ID = GlobalURL.BASE_PATH + 'factory-management/factories/factory/';

  public static GET_FACTORYMAPPING_BY_ID = GlobalURL.BASE_PATH + 'factory-management/factories/factorymapping/';
  public static GET_FACTORY_DETAILS = GlobalURL.BASE_PATH + 'factory-management/factories/factory/'; // '../assets/factoryList.json';

  // Add Edit Factory
  public static ADD_FACTORY_DETAILS = GlobalURL.BASE_PATH + 'factory-management/factories/configureadd';
  public static UPDATE_FACTORY_DETAILS = GlobalURL.BASE_PATH + 'factory-management/factories/configureedit';

  // public static GET_FACTORY_PRODUCT_LIST =  GlobalURL.BASE_PATH + 'Product/SearchMultipleProducts';
  public static GET_FACTORY_PRODUCT_LIST = GlobalURL.BASE_PATH + 'product-management/products/shareconfiguration/articles/';
  public static GET_REGISTERED_PRODUCT_LIST = GlobalURL.BASE_PATH + 'product-management/products/factory/';
  public static ADD_PRODUCT_TO_FACTORY = GlobalURL.BASE_PATH + 'product-management/products/factoryconfiguration';
  public static CONFIGURATION_PTC_UPDATE = GlobalURL.BASE_PATH + 'product-management/products/factory/configurationPTCupdate';

  public static VALIDATE_PRODUCT_COMPONENT_REGISTRATION = GlobalURL.BASE_PATH + 'production-management/validatecomponent';

  public static ADD_STEP_DATA = GlobalURL.BASE_PATH + 'production-management/configureproductionstep';
  public static EDIT_STEP_DATA = GlobalURL.BASE_PATH + 'product-management/products/configurationedit/';
  public static GET_ALL_STEPS = GlobalURL.BASE_PATH + 'production-management/productsteps/products/';
  public static GET_STEP_DATA = GlobalURL.BASE_PATH + 'production-management/configuration/products/';

  public static SHARE_CONFIGURATION = GlobalURL.BASE_PATH + 'product-management/products/configureshare/';

  public static COPY_CONFIGURATION = GlobalURL.BASE_PATH + 'product-management/products/configurecopy/';

  public static REMOVE_PRODUCT = GlobalURL.BASE_PATH + 'product-management/products/configurationremoveproduct';

  public static REMOVE_CONFIGURATION = GlobalURL.BASE_PATH + 'product-management/products/configurationremove';
  public static DELETE_STEP = GlobalURL.BASE_PATH + 'product-management/products/configurationremoveproductstep/';

  public static RENAME_STEP_PRODUCT_CONFIGURATION = GlobalURL.BASE_PATH + 'product-management/products/ConfigurationEditStepName?';

  public static REORDER_STEP_PRODUCT_CONFIGURATION = GlobalURL.BASE_PATH + 'product-management/products/EditStepOrderDetails';

  // COMMISSIONED/DE
  // public static GET_ARTICLE_DATA = GlobalURL.BASE_PATH + 'product-management/products/configurationremoveproductstep/';
  public static GET_ARTICLE_DATA = GlobalURL.BASE_PATH + 'installation-management/installation/article/'; // '../assets/adminProduct.json';

  public static MARK_COMMISSION = GlobalURL.BASE_PATH + 'installation-management/install/fieldinstallation';
  // mark decommission
  public static MARK_DECOMMISSION = GlobalURL.BASE_PATH + 'installation-management/install/fieldinstallation';

  public static EDIT_INSTALLATION = GlobalURL.BASE_PATH + 'installation-management/install/editinstallation';

  // production read only view
  public static PRODUCTION_VIEW = GlobalURL.BASE_PATH + 'quality-controller-management/quality/articles/';

  // service intervention api's
  // assembly tree
  public static PRODUCT_ASSEMBLY_TREE = GlobalURL.BASE_PATH + 'production-management/orders/order/articles/production?';
  // get add/replace component api
  public static ADD_REPLACE_COMPONENT_DETAILS = GlobalURL.BASE_PATH + 'installation-management/componentdetail/orders/';
  // save add/replace component
  public static SAVE_ADD_REPLACE_COMPONENT = GlobalURL.BASE_PATH + 'installation-management/savecomponents/article/';
  // service intervention readonly api
  public static SERVICE_INTERVENTION_ADD = GlobalURL.BASE_PATH + 'installation-management/installation/milestone/';
  // service intervention replace
  public static SERVICE_INTERVENTION_REPLACE = GlobalURL.BASE_PATH + 'quality-controller-management/quality/' +
    'getreplaceregisteredcomponent/milestoneSeqId/';
  public static COMMISSION_MILESTONE_READ = GlobalURL.BASE_PATH + 'installation-management/installation/GetChildMilestoneDetails/';
  // Get quality tag details
  public static GET_QUALITY_RESPONSE = GlobalURL.BASE_PATH + 'quality-management/qualitytag/qualitytag/';
  // Set quality tag details
  public static SET_QUALITY_RESPONSE = GlobalURL.BASE_PATH + 'quality-management/qualitytag/savequalitytagresponse';

  // Field Installation
  // Get header details
  public static FIELD_INSTALLATION = GlobalURL.BASE_PATH + 'installation-management/installation';

  // Get register assembly details
  public static GET_REGISTERED_ASSEMBLY = GlobalURL.BASE_PATH + 'installation-management/installation';

  // Mark as installed
  public static MARK_AS_INSTALLED = GlobalURL.BASE_PATH + 'installation-management/install/fieldinstallation';

  // Get draft installation value
  public static GET_DRAFT_VALUE = GlobalURL.BASE_PATH + 'installation-management/draft/article/';

  // Impersonate
  // Impersonate user
  public static IMPERSONATE_USER = GlobalURL.BASE_PATH + 'common-management/impersonate/userid/';

  // Upload order document
  public static ORDER_UPLOAD = GlobalURL.BASE_PATH + 'order-management/orders/upload';

  // edit production
  public static EDIT_PRODUCTION = GlobalURL.BASE_PATH + 'quality-controller-management/quality/editproductiondata';

  public static EDIT_ADDCOMP = GlobalURL.BASE_PATH + 'installation-management/installation/updatecomponentmilestone/';
  public static EDIT_REPLACECOMP = GlobalURL.BASE_PATH + 'quality-controller-management/quality/editregisteredcomponent';

  // advance search  api's
  public static ADD_QLTTAG = GlobalURL.BASE_PATH + 'quality-management/qualitytag/savequalitytag';
  // article search url's
  public static SEARCH_ARTICLE_NUMBER = GlobalURL.BASE_PATH + 'quality-management/validatebyarticlenumber/articles/';

  // advance search factory list
  public static SEARCH_FACTORY_LIST = GlobalURL.BASE_PATH + 'advance-search/factories/factorylist';
  // advanced search
  public static ADVANCED_SEARCH = GlobalURL.BASE_PATH + 'quality-management/advancedsearch/articles';
  // azure search api
  // public static AZURE_ADVANCED_SEARCH = environment.azureSearchURL + '/indexes/' +
  //   'advancesearch-product1-index/docs/search?api-version=2019-05-06';
  public static AZURE_ADVANCED_SEARCH = environment.azureSearchURL + '/indexes/' +
    'product-index/docs/search?api-version=2019-05-06';
  public static AZURE_SEARCH_SUB_ASSEMBLY = environment.azureSearchURL + '/indexes/' +
    'advance-search-subassembly-index/docs/search?api-version=2019-05-06';

  // azure search for component
  // public static AZURE_SEARCH_COMPONENT = environment.azureSearchURL + '/indexes/' +
  //   'advancesearch-component1-index/docs/search?api-version=2019-05-06';
  public static AZURE_SEARCH_COMPONENT = environment.azureSearchURL + '/indexes/' +
    'component-index/docs/search?api-version=2019-05-06';

  // azure search for batch type component
  public static AZURE_SEARCH_BATCHTYPE_COMPONENT = environment.azureSearchURL + '/indexes/' +
    'advancesearch-componentbn-index/docs/search?api-version=2019-05-06';
  // admin delete note
  public static DELETE_NOTE = GlobalURL.BASE_PATH + 'common-management/deletenote/milestone/';

  // customer order api
  public static CUSTOMER_ORDER = GlobalURL.BASE_PATH + 'customer-view/customer/';

  // customer product detail api
  public static PRODUCT_DETAIL = GlobalURL.BASE_PATH + 'quality-management/viewproductorderinformation/orders/';
  // blob url
  public static BLOB_DOWNLOAD = GlobalURL.BASE_PATH + 'common-management/downloadblob';

  public static CONTROLCARD_DOWNLOAD = GlobalURL.BASE_PATH + 'quality-controller-management/quality/multilevelCC/articles/';

  public static FILTERED_PRODUCT_TREE = GlobalURL.BASE_PATH + 'product-management/FilteredproductClassification'; // ../assets/tree.json';

  public static PRODUCT_MAPPING_ID = GlobalURL.BASE_PATH + 'product-management/FetchMappingId'; // ../assets/tree.json';

  public static AZURE_ONEMONTHDATA = environment.azureSearchURL + '/indexes/' +
    'productonemonth-index/docs/search?api-version=2020-06-30';

  public static AZURE_QUARTERDATA = environment.azureSearchURL + '/indexes/' +
    'product0to3month-index/docs/search?api-version=2020-06-30';

  public static AZURE_SIXMONTHDATA = environment.azureSearchURL + '/indexes/' +
    'product3to6month-index/docs/search?api-version=2020-06-30';

  public static AZURE_YEARDATA = environment.azureSearchURL + '/indexes/' +
    'product6to12month-index/docs/search?api-version=2020-06-30';

  public static AZURE_GTYEARDATA = environment.azureSearchURL + '/indexes/' +
    'productgt1y-index/docs/search?api-version=2020-06-30';

  public static AZURE_ONEMONTH_COMPONENT = environment.azureSearchURL + '/indexes/' +
    'componentonemonth-index/docs/search?api-version=2020-06-30';

  public static AZURE_QUARTER_COMPONENT = environment.azureSearchURL + '/indexes/' +
    'component0to3month-index/docs/search?api-version=2020-06-30';

  public static AZURE_SIXMONTH_COMPONENT = environment.azureSearchURL + '/indexes/' +
    'component3to6month-index/docs/search?api-version=2020-06-30';

  public static AZURE_YEAR_COMPONENT = environment.azureSearchURL + '/indexes/' +
    'component6to12month-index/docs/search?api-version=2020-06-30';

  public static AZURE_GTYEAR_COMPONENT = environment.azureSearchURL + '/indexes/' +
    'componentgt1y-index/docs/search?api-version=2020-06-30';
  public static XECM_DOCUMENT = GlobalURL.BASE_PATH + 'xECM-Document/Request';

  public static SEARCH_IQC_ORDER_DETAILS = GlobalURL.BASE_PATH + 'quality-controller-management/quality/IQC/articles/';

  public static SAVE_IQC_INSPECTION_ORDER = GlobalURL.BASE_PATH + 'quality-controller-manageMent/quality/saveinspectionprofileOrder';

  public static IQC_INSPECTION_DETAIL = GlobalURL.BASE_PATH + 'quality-controller-management/quality/IQC/Inspection/orderlines/';

  public static IQC_INSPECTION_DETAIL_OPEN = GlobalURL.BASE_PATH + 'quality-controller-management/quality/IQCOpen/Inspection/orderlines/';

  public static IQC_SAVE_INSPECTION = GlobalURL.BASE_PATH + 'quality-controller-management/quality/IQC/SaveInspection';

  public static IQC_INSPECTION_PROFILE = GlobalURL.BASE_PATH + 'quality-controller-management/quality/IQCProfile/Inspection/orderlines/';

  public static IQC_INSPECTION_PROFILE_OPEN = GlobalURL.BASE_PATH +
    'quality-controller-management/quality/IQCProfileOpen/Inspection/orderlines/';

  // IQC upcoming records
  public static GET_UPCOMING_INSPECTION = GlobalURL.BASE_PATH + 'quality-controller-management/quality/IQCUpcomingRecords';
  // IQC previous records
  public static GET_PREVIOUS_INSPECTION = GlobalURL.BASE_PATH + 'quality-controller-management/quality/IQCPreviousRecords';

  // IQC previous records search
  public static GET_PREVIOUS_INSPECTION_SEARCH = GlobalURL.BASE_PATH + 'quality-controller-management/quality/IQC/Inspection';

  public static IQC_INSPECTION_SAVE = GlobalURL.BASE_PATH + 'quality-controller-management/quality/IQCProfile/SaveInspection';

  public static IQC_SUPPLIER_DETAIL = GlobalURL.BASE_PATH + 'quality-controller-management/quality/' +
    'getsupplierinspectionprofiledetails/orderLineId/';

  public static Qt = GlobalURL.BASE_PATH + 'quality-management/qualitytagList/';

  public static QUALITY_DETAIL = GlobalURL.BASE_PATH + 'quality-management/getrespondedtags/';

  // Feeder Factory
  public static FEEDER_FACTORY_LIST = GlobalURL.BASE_PATH + 'factory-management/factories/FF/factorylist';

  public static FETCH_ISSUE_DETAILS = GlobalURL.BASE_PATH + 'quality-controller-management/quality/FF/';

  public static VALIDATE_SERIALFF = GlobalURL.BASE_PATH
    + 'quality-controller-management/quality/feederFactoryissues/reportissuesbyserialnumber/resolvingfactoryid/';

  public static SAVE_ISSUE_RESOLUTION = GlobalURL.BASE_PATH + 'quality-controller-management/quality/IssueResolution';

  public static SAVE_RAISE_ISSUE = GlobalURL.BASE_PATH + 'quality-controller-management/quality/SaveIssue';

  public static FEEDERSEARCH = GlobalURL.BASE_PATH + 'quality-controller-management/quality/SearchIssue';

  public static INTERFACE_LIST = GlobalURL.BASE_PATH + 'api/ProductionWebJob/FetchDropdownData';

  public static GET_ERROR_LIST = GlobalURL.BASE_PATH + 'api/ProductionWebJob/GetInterfaceRecordCount/';

  public static SITE_DASHBOARD_COUNT = GlobalURL.BASE_PATH + 'data-governance-management/SiteDashboardCount';

  public static GET_FAIL_ERROR_LIST = GlobalURL.BASE_PATH + 'api/ProductionWebJob/GetInterfaceFailedRecords/';

  public static GET_EDIT_DATA = GlobalURL.BASE_PATH + 'api/ProductionWebJob/GetOrderData/';

  public static GET_PRODUCTION_EDIT_DATA = GlobalURL.BASE_PATH + 'api/ProductionWebJob/GetProductionStepsStaging/';

  public static EDIT_PRODUCT_TREE = GlobalURL.BASE_PATH + 'api/ProductionWebJob/EditProductTreeData';

  public static ADD_SO_FORM = GlobalURL.BASE_PATH + 'api/ProductionWebJob/EditOrderData';

  public static DONE = GlobalURL.BASE_PATH + 'api/ProductionWebJob/UpdateStatus/';

  public static ADD_PO_FORM = GlobalURL.BASE_PATH + 'api/ProductionWebJob/EditOrderData';

  public static ADD_PRODUCTION_FORM = GlobalURL.BASE_PATH + 'api/ProductionWebJob/EditOrderData';

  public static SAVE_PRODUCT_TREE_STEP_DATA = GlobalURL.BASE_PATH + 'api/ProductionWebJob/UpdateProductionStepsStaging';

  public static GET_REASON_FOR_FAIL = GlobalURL.BASE_PATH + '/api/ProductionWebJob/GetReasonForInterfaceFailure';

  // CUSTOM REPORTS
  public static CUSTOM_REPORT_OPTIONS = GlobalURL.BASE_PATH + 'custom-report-management/customreport/getcustomreportOptions';

  public static INTERMEDIATE_OPTIONS = GlobalURL.BASE_PATH + 'custom-report-management/customreport/getcustomreportheaderoptions/';

  public static GET_CUSTOM_REPORT_DATA = GlobalURL.BASE_PATH + 'custom-report-management/customreport/getcustomreportDetails';
  // get Custom Report family List
  public static CR_FAMILY_FILTER = GlobalURL.BASE_PATH + 'custom-report-management/customreport/getcustomreportproductfamilyfilter';
  // get Custom Report Template List
  public static GET_CUSTOM_REPORT_TEMPLATE = GlobalURL.BASE_PATH + 'custom-report-management/getcustomreporttemplate/';

  public static SAVE_CUSTOM_REPORT_DATA = GlobalURL.BASE_PATH + 'custom-report-management/customreport/savecustomreportdetails';

  public static DOWNLOAD_CUSTOM_REPORT_DATA = GlobalURL.BASE_PATH + 'custom-report-management/customreport/exportCustomReport';
  // Get selected Template Details
  public static GET_SELECTED_TEMPLATE = GlobalURL.BASE_PATH + 'custom-report-management/customreport/getselectedtemplatedetails/';

  public static CustomReport_DOWNLOAD = GlobalURL.BASE_PATH + 'custom-report-management/customreport/getcustomreportdocument/';

  public static ExportCustomReport = GlobalURL.BASE_PATH + 'custom-report-management/customreport/exportCustomReport';
  //
  public static BLOB_DOWNLOAD_CUSTOM_REPORT = GlobalURL.BASE_PATH + 'common-management/downloadblob/customReport';

  public static DELETE_TEMPLATE = GlobalURL.BASE_PATH + 'custom-report-management/customreport/deletetemplate/';

  public static SearchDocument = GlobalURL.BASE_PATH + 'quality-controller-management/quality/GetAttachmentByParameter';

  public static GetDocumentDownload = GlobalURL.BASE_PATH + 'quality-controller-management/quality/GetDocumentDownloadDetails';

  public static GET_DRAWING = GlobalURL.BASE_PATH + 'quality-controller-management/quality/GetDrawing/';

  public static GET_STEP_DETAIL_BY_STEPNAME = GlobalURL.BASE_PATH + 'production-management/orders/';

  // get mail service
  // public static GET_INSPECTED_NOTIFICATION = GlobalURL.BASE_PATH + 'quality-controller-management/InspectionNotification/';
  public static GET_INSPECTED_NOTIFICATION = GlobalURL.BASE_PATH + 'quality-controller-management/InspectionNotification';

  public static SAVE_CCRP_DETAILS = GlobalURL.BASE_PATH + 'quality-management/qualitytag/saveCCRPDetails';

  public static FETCH_CCRP_BY_SERIALNUMBER = GlobalURL.BASE_PATH + 'quality-management/fetchccrpbyserialnumber';

  // supplier monthly report
  // supplier list
  public static GET_SUPPLIER_LIST_FOR_SUPPLIER = GlobalURL.BASE_PATH + 'order-management/orders/supplierlist';
  // factory list
  public static GET_FACTORY_LIST_FOR_SUPPLIER = GlobalURL.BASE_PATH + 'order-management/orders/factorylist';

  public static DOWNLOAD_SUPPLIER_REPORT = GlobalURL.BASE_PATH + 'order-management/orders/DownloadSupplierReport';
  // Data-Governance
  public static SEARCH_DATA_GOVERNANCE = GlobalURL.BASE_PATH + 'data-governance-management/searchcount';

  public static SEARCH_DETAILS_DATA_GOVERNANCE = GlobalURL.BASE_PATH + 'data-governance-management/categorywiseArticleList';

  public static SEARCH_DETAILS_TREE_SUBNODE_DATA_GOVERNANCE = GlobalURL.BASE_PATH + 'data-governance-management/treeHierarchy';

  public static SEARCH_DATA_GOVERNANCE_DASHBOARDCOUNT = GlobalURL.BASE_PATH + 'data-governance-management/dashboardCount';

  public static SITE_DASHBOARDCOUNT = GlobalURL.BASE_PATH + 'data-governance-management/SiteDashboardCount';

  public static SUBCATEGORY_ISSUE_DETAIL = GlobalURL.BASE_PATH + 'data-governance-management/SubcategoryIssueDetail';

  public static STOP_TRACKING_DATA_GOVERNANCE = GlobalURL.BASE_PATH + 'data-governance-management/stoptracking';

  public static FILTERED_ARTICLE_LIST = GlobalURL.BASE_PATH + 'data-governance-management/FilteredArticleList';

  public static DIGITAL_PRODUCTS_STEP_COMPARISON = GlobalURL.BASE_PATH + 'data-governance-management/DigitalProductsStepComparison';

  public static NON_DIGITAL_PRODUCT_STEPS_DEVIATION = GlobalURL.BASE_PATH + 'data-governance-management/NonDigitalProductStepsDeviation';

  public static SAVE_NON_DIGITAL_PRODUCT_STEPS_DEVIATION = GlobalURL.BASE_PATH +
    'data-governance-management/SaveNonDigitalProductStepsDeviation';

  public static IS_USER_DATA_GOVERNANCE_ENABLED = GlobalURL.BASE_PATH + 'data-governance-management/IsUserDataGovernanceEnabled';

  public static FILTERED_SUPPLIER_LIST = GlobalURL.BASE_PATH + 'data-governance-management/FilteredSupplierList';

  public static FILTERED_COMPONENT_ARTICLE_LIST = GlobalURL.BASE_PATH + 'data-governance-management/FilteredArticledetailList';

  public static COMPONENT_SEARCH_COUNT = GlobalURL.BASE_PATH + 'data-governance-management/Componentsearchcount';

  public static COMPONENT_COMMON_EXPORT = GlobalURL.BASE_PATH + 'data-governance-management/ComponentCommonexport';

  public static NON_COMPLAINT_PO = GlobalURL.BASE_PATH + 'data-governance-management/NonComplaintPO/';
  // Data-Governance ends here
  public static PRODUCTION_STEP = GlobalURL.BASE_PATH + 'api/ProductionWebJob/GetProductionStepsStaging';

  // CR 247, Serial range option for barcode utility, 6-Oct-2023
  public static SUPPLIER_COMPONENT_DETAILS = GlobalURL.BASE_PATH +
    'order-management/orders/SupplierComponentDetails/supplierGenerateBarcodeViewModel';

  public static FACTORY_PRODUCT_SAVE_EXCEL_DATA = GlobalURL.BASE_PATH + 'product-management/factoryproductssaveexceldata';

  public static SAVE_SERVIS_DETAILS = GlobalURL.BASE_PATH + '/quality-management/qualitytag/saveServISDetails';

  public static SEARCH_DOCUMENTS = GlobalURL.BASE_PATH + 'supplier-management/suppliers/searchdocuments';

  public static SUPPLIER_DASHBOARD_COUNT = GlobalURL.BASE_PATH + 'supplier-management/suppliers/SupplierDashboardCount';

  public static DOWNLOAD_ALL = GlobalURL.BASE_PATH + 'common-management/ZipBlobList';

  public static CERT_DOCUMENT_LIST = GlobalURL.BASE_PATH + 'quality-controller-management/Supplier/PreviewCertficates';

  public static SAVE_SUPPLIER_DOCUMENT_INSPECTION_STATUS = GlobalURL.BASE_PATH + 'order-management/Document-Inspection';

  public static NOTIFICATION_MESSAGE = GlobalURL.BASE_PATH + 'supplier-management/suppliers/notificationmessages';

  public static NOTIFICATION_MESSAGE_AD_SEARCH = GlobalURL.BASE_PATH + 'production-management/notifications/list';

  public static CLEAR_NOTIFICATION_AD_SEARCH = GlobalURL.BASE_PATH + 'production-management/notifications/closure';

  public static NOTIFICATION_COUNT = GlobalURL.BASE_PATH + 'supplier-management/suppliers/notificationcount';

  public static CLEAR_NOTIFICATION = GlobalURL.BASE_PATH + 'supplier-management/suppliers/clearSupplierNotification';

  public static REDBOOK_PAGES = GlobalURL.BASE_PATH + 'Redbook/Pages/article/';

  public static REDBOOK_PREDEFINED_PAGES = GlobalURL.BASE_PATH + 'Redbook/GetPredefinedRedbook/article/';

  public static REDBOOK_GET_COVER_SHEET_DETAILS = GlobalURL.BASE_PATH + 'Redbook/GetCoverSheetDetails';

  public static DELETE_REDBOOK_PAGE = GlobalURL.BASE_PATH + 'Redbook/deleteRedbookPage';
  public static SAVE_FINSH_REDBOOK_PAGE = GlobalURL.BASE_PATH + 'Redbook/SaveUserControlDetails';
  public static REDBOOK_PRODUCTION_TEST_DATA = GlobalURL.BASE_PATH + 'Redbook/Documents/article/';

  public static REDBOOK_PRODUCTION_TEST_DATA_SAVE_DOC_LIST = GlobalURL.BASE_PATH + 'Redbook/Save-DocumentsId';

  public static REDBOOK_GET_COMPONENT_TRACEBILITY_DATA = GlobalURL.BASE_PATH + 'Redbook/GetConsumptionDetails';

  public static REDBOOK_SAVE_COMPONENT_TRACEABILITY_DATA = GlobalURL.BASE_PATH + 'Redbook/Save-ComponentConsumption';

  public static REDBOOK_GET_NEW_BLANK_PAGE_DATA = GlobalURL.BASE_PATH + 'Redbook/GetBlankPageData';

  public static REDBOOK_GET_TEST_REPORT_DATA = GlobalURL.BASE_PATH + 'Redbook/GetFinalTestReportAndAdditionalDocumentList/article/';

  public static REDBOOK_SAVE_ATTACHMENTS = GlobalURL.BASE_PATH + 'Redbook/Save-Attachments';

  public static REDBOOK_REVIEW_PUBLISH = GlobalURL.BASE_PATH + 'Redbook/GenerateRedbook/article/';

  public static REDBOOK_GET_CERTIFICATE_CATEGORY = GlobalURL.BASE_PATH + 'Redbook/GetCertificateCategory';

  public static REDBOOK_GET_CONSUMPTION_DETAILS = GlobalURL.BASE_PATH + 'Redbook/GetDocumentIDDetails/documentId/';
  // for production operator enhancement
  public static PRODUCTION_NOT_STARTED_COUNT = GlobalURL.BASE_PATH +
    'production-management/production-not-started/serialnumbers/count';

  public static UNASSOCIATED_SO = GlobalURL.BASE_PATH +
    'production-management/unassociated-so/serialnumbers/list';

  public static PRODUCTION_NOT_STARTED_SERIALNUMBER = GlobalURL.BASE_PATH +
    'production-management/production-not-started/serialnumbers/list';

  public static PRODUCTION_WIP_SERIALNUMBER_COUNT = GlobalURL.BASE_PATH +
    'production-management/production-wip/serialnumbers/count';

  public static PRODUCTION_WIP_STEPWISE_SERIALNUMBER_COUNT = GlobalURL.BASE_PATH +
    'production-management/production-wip/stepwise-serialnumbers/count';

  public static PRODUCTION_WIP_STEPWISE_SERIALNUMBER_LIST = GlobalURL.BASE_PATH +
    'production-management/production-wip/stepwise-serialnumbers/list';

  public static PRODUCTION_NOT_STARTED_COUNT_SERIALNUMBER_DISCARD = GlobalURL.BASE_PATH +
    'production-management/production-not-started/serialnumbers/discard';

  public static PRODUCTION_NOT_STARTED_SERIALNUMBER_EXPORT =
    GlobalURL.BASE_PATH + 'production-management/production-not-started/serialnumbers/export';

  public static GET_PRODUCT_SERIAL_NUMBER_RANGE = GlobalURL.BASE_PATH +
    'production-management/GetProductSerialNoRange/SerialNoRange/';

  public static ADD_FINAL_TESTING_DOCUMENT = GlobalURL.BASE_PATH +
    'production-management/UploadFinalTestingSerialNoRange/documents/articles/';

  public static GET_SALES_ORDERS_BY_FACTORY = GlobalURL.BASE_PATH +
    'production-management/getsalesorderbyfactory/supplierid/';

  public static GET_SERIAL_NUMBERS_BY_SALES_ORDERS = GlobalURL.BASE_PATH +
    'production-management/getserialNumbersBySalesOrders/SalesOrder/';

  public static GET_FINAL_TEST_DOCUMENT_BY_SERIAL_NUMBERS = GlobalURL.BASE_PATH +
    'production-management/getfinaltestdocumentBySerialNumbers/Article/';

  public static GET_COPY_SHARE_CONFIG_DATA = GlobalURL.BASE_PATH + 'product-management/products/factory/';

  public static RESET_ORDERS_DATA = GlobalURL.BASE_PATH + 'order-management/orders/ResetOrderDetails/order/';

  // export data on data quality search
  public static DATA_GOVERNANCE_MANAGEMENT_COMMOM_EXPORT = GlobalURL.BASE_PATH + 'data-governance-management/common-export';

  public static GET_IQC_SUMMARY_DATA = GlobalURL.BASE_PATH +
    'quality-controller-management/quality/DownloadIQCInspectionData/orderLineId/';

  public static REPROCESS_CLOSEDORDERS_DATA = GlobalURL.BASE_PATH + 'order-management/orders/ClosedOrderDetails/order/';

  public static VALIDATE_UNFINSHED_SUB_ASSEMBLY = GlobalURL.BASE_PATH + 'production-management/ValidateUnfinshedSubAssembly';

  public static GET_PRODUCTION_COMPLETE_DETAILS = GlobalURL.BASE_PATH + 'production-management/GetProductionCompleteDetails/serialNumber/';
  // Old QC card
  public static GET_OLD_QC_CARD = GlobalURL.BASE_PATH + 'quality-controller-management/GetLoggedAutoGeneratedQcCard/ArticleNumber/';

  public static VALIDATE_MASS_PRODUCTION_DATA = GlobalURL.BASE_PATH + 'production-management/ValidateMassProductionData';

  public static CREATE_MASS_PRODUCTION = GlobalURL.BASE_PATH + 'production-management/CreateMassProduction';

  public static PRODUCT_FILTER_LIST = GlobalURL.BASE_PATH + 'product-management/products/productfilters';

  public static PRODUCT_WIHTDRAW = GlobalURL.BASE_PATH + 'quality-controller-management/Supplier/WithdrawnProduct';

  public static QC_RESTRUCTING = GlobalURL.BASE_PATH + 'quality-controller-management/restructured/quality/serialNumber/';

  public static RESTRUCTURED_CONTROL_CARD = GlobalURL.BASE_PATH + 'quality-controller-management/quality/RestructuredControlCard';

  public static SERIAL_NUMBER_SALES_ORDER = GlobalURL.BASE_PATH +
    'quality-controller-management/productdocument/GetSerialNumberbySalesorders';

  public static GET_TYPE_OF_SEARCH_REQUEST = GlobalURL.BASE_PATH +
    'quality-controller-management/productdocument/GetTypeOfSearchRequest';


  // ACTAS
  public static ACTAS_ADD_TEMPLATE = GlobalURL.BASE_PATH + 'actas/AddActasTemplate';

  public static ACTAS_GET_ALL_TEMPLATE_LIST = GlobalURL.BASE_PATH + 'actas/GetTemplateDetails';

  public static ACTAS_GET_TEMPLATE_DETAILS = GlobalURL.BASE_PATH + 'actas/GetTemplateDetailByTemplateId/';

  public static ACTAS_EDIT_TEMPLATE_DETAILS = GlobalURL.BASE_PATH + 'actas/UpdateActasTemplate/templateId/';

  public static ACTAS_DELETE_TEMPLATE = GlobalURL.BASE_PATH + 'actas/DeleteActasTemplate/templateId/';

  public static ACTAS_GENERATE_XML = GlobalURL.BASE_PATH + 'actas/GenerateActasXml';

  public static ACTAS_CIRCUIT_BREAKER_SAVE = GlobalURL.BASE_PATH + 'actas/CircuitBreaker';

  public static UPDATE_ACTAS_CONFIGURATION = GlobalURL.BASE_PATH + 'product-management/products/configurationupdateActas';

  public static ACTAS_GET_CIRCUIT_BREAKER_CONFIGURATION = GlobalURL.BASE_PATH + 'actas/CircuitBreaker/';

  public static PRODUCTION_DEPLOYMENT_ALERT = GlobalURL.BASE_PATH + 'common-management/send-production-deployment-alert';

  public static REFERENCE_DOCUMENT_LIST = GlobalURL.BASE_PATH + 'common-management/getreferencedocumentlist/order/';

  public static GET_RECORDED_PRODUCTION_TIME_ARTICLE_WISE = GlobalURL.BASE_PATH +
    'production-management/GetRecordedProductionTimeArticleWise';

  public static GET_RECORDED_PRODUCTION_TIME_DETAILS = GlobalURL.BASE_PATH + 'production-management/GetRecordedProductionTimeDetails';

  public static GENERATE_SERIAL_NUMBER_REPORT_FOR_PTC = GlobalURL.BASE_PATH +
    'production-management/generatereportforproductiontimecapturedetails';

  // CR 180, SAP Production Order, 3-Aug-23
  public static GET_PRODUCTION_ORDER_DETAILS = GlobalURL.BASE_PATH + 'production-management/GetProductionOrderDetails';

  // Start CR 200, Need to introduce Hold Status in DPS for Quality Controller for DPS as MES type factories, 11 Aug 2023
  public static ONHOLD_PRODUCT = GlobalURL.BASE_PATH + 'quality-controller-management/Supplier/ProductOnHold';

  public static RELEASED_PRODUCT = GlobalURL.BASE_PATH + 'quality-controller-management/Supplier/ReleaseProductOnHold';
  // End CR 200, Need to introduce Hold Status in DPS for Quality Controller for DPS as MES type factories, 11 Aug 2023

  // Start CR 199, Need for provisioning to replace uploaded certificates for finished and ongoing products, 14 Aug 2023
  public static GET_DOCUMENT_COUNT_LIST = GlobalURL.BASE_PATH + 'production-management/GetDocumentCountList/articleNumber/';

  public static REPLACE_PRODUCTION_DOCUMENT = GlobalURL.BASE_PATH + 'production-management/replace-production-document';
  // End CR 199, Need for provisioning to replace uploaded certificates for finished and ongoing products, 14 Aug 2023

  // RTI Dashboard
  public static GET_RTI_INDEX_DETAILS = GlobalURL.BASE_PATH + 'rti/GetRTIIndexDetails/';

  public static GET_RTI_ADVANCESEARCH_DETAILS = GlobalURL.BASE_PATH + 'rti/getrtiproductdetailsadvancesearch';

  public static SAVE_RTI_PRODUCT_INDEX = GlobalURL.BASE_PATH + 'rti/SaveUpdateRTIProductIndex';

  public static GET_RTI_PROUDUCT_FILTER_DETAILS = GlobalURL.BASE_PATH + 'rti/getrtiproductfilterdetail';

  public static ARCHIVED_RTI_INDEX = GlobalURL.BASE_PATH + 'rti/ArchievedRTIIndex/';

  public static GET_RTI_PRODUCT_STATUS_DETAILS = GlobalURL.BASE_PATH + 'rti/getrtiproductstatusdetails';

  public static SAVE_RTI_PRODUCT_DETAILS = GlobalURL.BASE_PATH + 'rti/saveproductmasterdetail/product';

  public static DELETE_RTI_PRODUCT_LIST = GlobalURL.BASE_PATH + 'rti/deleteproductmasterdetail/product/';

  public static EDIT_RTI_PRODUCT_LIST = GlobalURL.BASE_PATH + 'rti/updateproductmasterdetail/product/';

  public static COPY_RTI_PRODUCT_LIST = GlobalURL.BASE_PATH + 'rti/copyproductmasterdetail/product/';

  public static UPLOAD_RTI_INDEX = GlobalURL.BASE_PATH + 'rti/UploadRTIIndex/';

  public static HISTORY_LOG_DETAILS = GlobalURL.BASE_PATH + 'rti/getrtihistorylogdetails';

  public static SAVE_RTI_MEASUREMENT_DETAILS = GlobalURL.BASE_PATH + 'rti/SaveUpdateRTIProductMeasurement';

  public static GET_RTI_SELECTED_PRODUCTTYPE = GlobalURL.BASE_PATH + 'rti/getrtiproductfilterselectproducttype/';

  public static GET_RTI_MEASUREMENT_DETAILS = GlobalURL.BASE_PATH + 'rti/GetRTIMeasurementDetails/';

  public static ARCHIVED_RTI_MEASUREMENT = GlobalURL.BASE_PATH + 'rti/ArchievedRTIMeasurement/';

  public static UPLOAD_RTI_MEASUREMENT = GlobalURL.BASE_PATH + 'rti/UploadRTIMeasurement/';

  public static GET_RTI_BOUNDARY_CONDITION_DETAILS = GlobalURL.BASE_PATH + 'rti/getrtiboundaryconditiondetails';

  public static SAVE_RTI_BOUNDARY_CONDITION_DETAILS = GlobalURL.BASE_PATH + 'rti/savertiboundaryconditiondetails';

  public static GENERATE_RTI_PDF = GlobalURL.BASE_PATH + 'rti/generateproductdetailindexpdf/product/';

  public static GET_RTI_UNIT_DETAILS = GlobalURL.BASE_PATH + 'rti/getrtiunitdetails/product/';

  public static SAVE_RTI_UNIT_DETAILS = GlobalURL.BASE_PATH + 'rti/savertiunitdetails';

  public static GET_RTI_OPERATOR_DETAILS = GlobalURL.BASE_PATH + 'rti/getrtioperatordetails';

  public static SAVE_RTI_OPERATOR_DETAILS = GlobalURL.BASE_PATH + 'rti/savertiunitdetails/operator/';

  // Start 154 (c) 154 (d), Recording/Reporting production incidents, 5 Oct 2023
  public static SAVE_INCIDENT_CONFIGURATION = GlobalURL.BASE_PATH + 'production-management/SaveIncidentConfiguration';

  public static GET_INCIDENT_CONFIGURATION_DATA = GlobalURL.BASE_PATH + 'production-management/GetIncidentConfigurationData';

  public static GET_INCIDENT_CATEGORY = GlobalURL.BASE_PATH + 'production-management/GetIncidentCategory';

  public static GET_INCIDENT_SUB_CATEGORY = GlobalURL.BASE_PATH + 'production-management/GetIncidentSubCategory/CategoryId/';

  public static GET_INCIDENT_USERS = GlobalURL.BASE_PATH + 'production-management/GetIncidentUsers/FactoryGisno/';

  public static GET_INCIDENT_TEMPLATE_COLUMN = GlobalURL.BASE_PATH + 'production-management/GetIncidentTemplateColumn';

  public static GET_INCIDENT_COUNT = GlobalURL.BASE_PATH + 'production-management/GetIncidentCount';

  public static RAISE_INCIDENT = GlobalURL.BASE_PATH + 'production-management/RaiseIncident';

  public static GET_ALL_INCIDENTS = GlobalURL.BASE_PATH + 'production-management/GetIncidentDetailsList';

  public static GET_INCIDENT_DETAILS = GlobalURL.BASE_PATH + 'production-management/GetIncidentDetails';

  public static GET_ALL_INCIDENT_STATUS_DROPDOWN = GlobalURL.BASE_PATH + 'production-management/GetIncidentstatus';

  public static GET_INCIDENT_LOG_DATA = GlobalURL.BASE_PATH + 'production-management/GetIncidentLogData';

  public static GET_INCIDENT_DESCRIPTION_ATTACHMENT = GlobalURL.BASE_PATH +
    'production-management/GetIncidentDescriptionAttachment/IncidentID/';

  public static GENERATE_INCIDENT_LOG_DATA_REPORT = GlobalURL.BASE_PATH + 'production-management/GenerateIncidentLogDataReport';
  // End 154 (c) 154 (d), Recording/Reporting production incidents, 5 Oct 2023

  public static UPDATE_CONFIG_EXT_MEAS = GlobalURL.BASE_PATH + 'product-management/products/factory/updateconfigurationexternalserailno?';

  public static UPDATE_CONFIG_ENGINEER_TO_ORDER = GlobalURL.BASE_PATH +
    'product-management/products/factory/updateconfigurationengineertoorder?';

  public static GET_PRODUCTION_ORDER_DOCUMENT_DETAILS = GlobalURL.BASE_PATH +
    'production-management/getproductionorderdocumentdetails/ProductionOrder/';
  // Start CH38-Hierarchical BOM view with traceable components flagged
  public static GET_BOM_DETAILS = GlobalURL.BASE_PATH +
    'production-management/GetSAPBOMHierarchyDetail/';
  // End CH38-Hierarchical BOM view with traceable components flagged

  // Lumada changes for download
  public static LUMADA_PRODUCTS_DOWNLOAD = GlobalURL.BASE_PATH +
    'quality-management/lumadaproductsdownload';

  // Lumada changes for upload file
  public static LUMADA_UPLOAD_FILE = GlobalURL.BASE_PATH +
    'quality-controller-management/lumadauploadfile';

  // Start CR 225 - Product Configuration - Conditional production step creation
  public static GET_CONFIGURATION_LIST = GlobalURL.BASE_PATH + 'product-management/products/GetVariantsList';
  public static COPY_CONFIGURATION_VARIANT = GlobalURL.BASE_PATH + 'product-management/products/configurevariantcopy';
  // End CR 225 - Product Configuration - Conditional production step creation
  public static REGISTER_DIGITAL_PRODUCTS = GlobalURL.BASE_PATH + 'product-management/RegisterDigitalcomponents';
  public static FILTER_INCIDENT = GlobalURL.BASE_PATH + 'production-management/GetFilteredIncidentConfigurationData';
  public static DELETE_INCIDENT = GlobalURL.BASE_PATH + 'production-management/DeleteIncidentConfiguration/';
  public static EDIT_INCIDENT = GlobalURL.BASE_PATH + 'production-management/ModifyIncidentConfiguration';

  // Start 212(b), Digital Factory Integrate BOM in DPS Production - US DTB, 02-April-2024
  public static FETCH_SAP_PRODUCTION_ORDER_DATA = GlobalURL.BASE_PATH + 'production-management/GetProductionOrderFromIntegration/';
  public static GET_DIGITAL_CONFIGURATION = GlobalURL.BASE_PATH + 'production-management/GetDigitalComponentsConfiguration/';
  public static EDIT_DIGITAL_CONFIGURATION = GlobalURL.BASE_PATH + 'product-management/digitalproducts/configurationedit';
  public static CHECK_SAP_PRODUCTION_ORDER_DATA = GlobalURL.BASE_PATH + 'production-management/CheckPRoductionOrders/';
  public static SEND_SAP_ACKNOWLEDGEMENT = GlobalURL.BASE_PATH + 'production-management/SendPOAcknowledgementToSAP';
  // End 212(b), Digital Factory Integrate BOM in DPS Production - US DTB, 02-April-2024

  // Start CH4, Notification Management
  public static CATEGORY_LIST = GlobalURL.BASE_PATH + 'production-management/GetNotificationManagementCategory/';
  public static USER_LIST = GlobalURL.BASE_PATH + 'user-management/users/GetNotificationUserMappingList/';
  public static SUBMIT_NOTIFICATION = GlobalURL.BASE_PATH + 'production-management/NotificationUserMapping/savenotificationusermapping';
  // End CH4, Notification Management

  // Start CR 211 Digital RTI Dashboard to be developed within DPS to capture RTR , 18-March-2024
  public static GET_RTI_TAG_DETAILS = GlobalURL.BASE_PATH + 'rti/GetRTITagDetails';
  public static SAVE_RTI_TAG_DETAILS = GlobalURL.BASE_PATH + 'rti/SaveUpdateRTIProductTag';
  public static ARCHIVE_RTI_TAG = GlobalURL.BASE_PATH + 'rti/ArchievedRTITag';
  // End CR 211 Digital RTI Dashboard to be developed within DPS to capture RTR , 18-March-2024

  // Uer role API added for Enhancement
  public static USER_CATEGORY_SCREEN_MASTER_DETAIL = GlobalURL.BASE_PATH + 'user-management/users/getcategoryscreenmasterdetail';
  public static GET_ALL_FACTORYLIST = GlobalURL.BASE_PATH + 'factory-management/factories/getallfactorylist';
  public static SAVE_EDIT_USER_DETAIL = GlobalURL.BASE_PATH + 'user-management/users/saveedituserdetail';
  public static USER_DETAIL_BY_USER_ID = GlobalURL.BASE_PATH + 'user-management/users/userid/';

  // CH-216 Digital Factory ACTAS iteration 2- Export to XMl format, 30-April-2024
  public static GET_PRODUCTION_STEP_NAMING_LIST = GlobalURL.BASE_PATH + 'factory-management/factories/getProductionstepNamingList';

  public static GET_CONFIG_FACTORY = GlobalURL.BASE_PATH + 'factory-management/factories/getConfigurationForSAP/';

  public static SAVE_FACTORY_CONFIG = GlobalURL.BASE_PATH + 'factory-management/factories/saveConfigurationForSAP';

  // CH32 QC restructing: Redirect to QC page
  public static GET_SECTION_DETAILS = GlobalURL.BASE_PATH + 'quality-controller-management/restructured/GetRestructuredQualityCardViewMore';
  // CH32 QC restructing: Redirect to QC page

  // Pause functionality AHIT
  public static GET_FACTORIES = GlobalURL.BASE_PATH + 'factories';
  public static PRODUCTION_STEPS = GlobalURL.BASE_PATH + 'productionSteps';
  
  // Report Quality Issues
  public static DEFECT_TYPES = GlobalURL.BASE_PATH + 'api/SapQualityIssue';
  public static SAVE_QUALITY_ISSUE = GlobalURL.BASE_PATH + 'api/SapQualityIssue/Report';

  constructor() { }

}
